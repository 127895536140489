import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Link } from "gatsby"
import kebabCase from "lodash/kebabCase"


const Blog = ({ data }) => {
  const posts = data.allFile.edges
  const latests = data.latestPosts.edges
  const tagGroup = data.tagsGroup.group

  return (
    <Layout>
      <SEO title="Blog" />
      <h1>Blog</h1>
      <div className="container mt-5">
        <div className="row justify-content-center">
          <div className="col-12 text-center">
            <div className="section-title mb-4 pb-2">
              <h4 className="title mb-4">Blog</h4>
            </div>
          </div>
        </div>
      </div>
      <div className="container mt-5 mb-5">
        <div className="row">
          <div className="col-lg-8 col-md-6">
            <div className="row mr-lg-2">
              {posts.map(({ node }) => {
                return (
                  <Link
                    className="title text-dark"
                    to={node.childMarkdownRemark.fields.slug}
                  >
                    <div className="col-lg-6 mb-4 pb-2">
                      <div className="blog position-relative overflow-hidden shadow rounded">
                        <div className="position-relative">
                          {node.childMarkdownRemark.frontmatter.BlogImage ? (
                            <img
                              className="img-fluid rounded-top"
                              src={node.childMarkdownRemark.frontmatter.BlogImage}
                              alt={node.childMarkdownRemark.frontmatter.title}
                            />
                          ) : null}
                          <div className="overlay rounded-top bg-dark" />
                        </div>
                        <div className="content p-4">
                          <h4>
                            <Link
                              className="title text-dark"
                              to={node.childMarkdownRemark.fields.slug}
                            >
                              {node.childMarkdownRemark.frontmatter.title}
                            </Link>
                          </h4>
                          <div className="post-meta mt-3">
                            <Link
                              className="text-muted float-right readmore"
                              to={node.childMarkdownRemark.fields.slug}
                            >
                              <p
                                dangerouslySetInnerHTML={{
                                  __html:
                                    node.childMarkdownRemark.frontmatter
                                      .description ||
                                    node.childMarkdownRemark.excerpt,
                                }}
                              />
                              <i className="mdi mdi-chevron-right" />
                            </Link>
                          </div>
                        </div>
                        <div className="author">
                          <small className="text-light date">
                            <i className="mdi mdi-calendar-check" />{" "}
                            {node.childMarkdownRemark.frontmatter.date}
                          </small>
                        </div>
                      </div>
                    </div>
                  </Link>
                )
              })}
              {/* PAGINATION START */}

            </div>
          </div>
          {/* BLog End */}
          {/* START SIDEBAR */}
          <div className="col-lg-4 col-md-6 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
            <div className="sidebar mt-sm-30 p-4 rounded shadow">
              <div className="widget mb-4 pb-2">
                <h4 className="widget-title">Kategoriler</h4>
                <ul className="list-unstyled mt-4 mb-0 catagories">
                  {tagGroup.map(tag => (
                    <li key={tag.fieldValue}>
                      <Link to={`/tags/${kebabCase(tag.fieldValue)}/`}>
                        {tag.fieldValue}{" "}
                        <span className="float-right"> {tag.totalCount}</span>
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>

              <div className="widget mb-4 pb-2">
                <h4 className="widget-title">Son İçerikler</h4>
                <div className="mt-4">
                  {latests.map(({ node }) => {
                    return (
                      <Link to={node.childMarkdownRemark.fields.slug}>
                        <div className="clearfix post-recent">

                          <div className="post-recent-thumb float-left">

                             {node.childMarkdownRemark.frontmatter.BlogImage ? (
                                  <img 
                                  className="img-fluid rounded"
                                   src={node.childMarkdownRemark.frontmatter.BlogImage}
                                   alt={node.childMarkdownRemark.frontmatter.title}
                                 />
                              ) : null}
                          </div>
                          <div className="post-recent-content float-left">
                            <a> {node.childMarkdownRemark.frontmatter.title}</a>
                            <span className="text-muted mt-2">
                              {node.childMarkdownRemark.frontmatter.date}
                            </span>
                          </div>

                        </div>
                      </Link>
                    )
                  })}

                </div>
              </div>
            </div>
          </div>
          {/*end col*/}
          {/* END SIDEBAR */}
        </div>
        {/*end row*/}
      </div>
      {/*end container*/}
    </Layout>
  )
}

export default Blog

export const pageQuery = graphql`
query {
  allFile(filter: {relativeDirectory: {eq: "blog"}}, sort: {fields: childMarkdownRemark___frontmatter___date, order: DESC}) {
    edges {
      node {
        childMarkdownRemark {
          excerpt(pruneLength: 150)
          fields {
            slug
          }
          frontmatter {
            BlogImageAlt
            date(formatString: "LLLL", locale: "TR")
            BlogImage 
            title
          }
        }
      }
    }
  }
  tagsGroup: allMarkdownRemark(limit: 20) {
    group(field: frontmatter___tags) {
      fieldValue
    }
  }
   latestPosts: allFile(filter: {relativeDirectory: {eq: "blog"}}, sort: {fields: childMarkdownRemark___frontmatter___date, order: DESC}, limit: 5) {
     edges {
      node {
        childMarkdownRemark {
          fields {
            slug
          }
          frontmatter {
            BlogImageAlt
            date(formatString: "LL", locale: "TR")
            BlogImage
            title
          }
        }
      }
    
    }
  }
  }
`
